// @ts-check

import { api } from '../api';

const API_PUBLIC_ADS_PATH = '/public/ads';

/**
 * @import { AxiosResponse } from 'axios';
 * @import { IVideoPublicDto } from '../channel/videos.dto';
 */

/**
 * @param {number} duration
 * @param {string} userLanguage
 * @returns {Promise<AxiosResponse<{ ads: IVideoPublicDto[] }>>}
 */
export const fetchAds = async (duration, userLanguage) => api.get(`${API_PUBLIC_ADS_PATH}/${duration}/${userLanguage}`);
