// @ts-check

import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Alert } from 'reactstrap';

import { useQueryClient } from 'react-query';
import Identity, { IdentityError } from '../../lib/Identity';

const { VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT } = import.meta.env;

const BEEYOU_SESSION_LOCALSTORAGE_KEY = 'beeyou.session';

/** @enum {typeof Role[keyof typeof Role]} */
export const Role = /** @type {const} */({
	ADMIN: 'ROLE_ADMIN',
	GUEST: 'ROLE_GUEST',
	USER: 'ROLE_USER',
});

/**
 * Simplified authentication mechanism using a single token (id_token)
 */
class AuthenticationIdentity extends Identity {
	baseURL = VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT;

	localstorageSessionKey = BEEYOU_SESSION_LOCALSTORAGE_KEY;

	isSessionExpired() {
		return (
			!this.session.id_token
			|| this.session.id_token.expiresAt <= Date.now()
		);
	}

	getIdTokenSync() {
		const { id_token } = this.session;
		if (!id_token) throw new IdentityError('id_token_not_found', 'ID Token not found.');
		if (!this.isTokenValid(id_token)) {
			this.logout(); // this is an weird case, a logout is preferred
			throw new IdentityError('id_token_invalid', 'ID Token is invalid.');
		}
		return id_token;
	}

	/**
	 * @returns {User}
	 */
	getUserSync() {
		const { payload } = this.getIdTokenSync();
		return /** @type {User} */ (payload);
	}

	/**
	 * @param {string} accessToken
	 * @returns {Promise<import('../../lib/Identity').Tokens>}
	 */
	async requestToken(accessToken) {
		const { data: tokens } = await this.api.post(
			'/token',
			null,
			{
				headers: { authorization: `Bearer ${accessToken}` },
			},
		);
		return tokens;
	}

	async getRefreshToken() {
		return this.getAccessToken();
	}
}

export const identity = new AuthenticationIdentity().initialize();

/**
 * TODO: complete User type ?
 * @typedef {{
 *  role: Role,
 *  sub: string,
 * }} User
 */

/**
 * @typedef {{
 * 	isLoggedIn?: boolean,
 * 	isGuest?: boolean,
 * 	isPending?: boolean,
 * 	isErrorSevere?: boolean,
 * 	user?: User,
 * 	guest?: User,
 * 	error?: string,
 * }} AuthenticationState
 */

/**
 * @typedef {AuthenticationState & {
 *  isSessionActive: boolean,
 *  login: (
 *  	...args: Parameters<AuthenticationIdentity['login']>
 *  ) => ReturnType<AuthenticationIdentity['login']>,
 *  loginAfterGoogleSignUp: (
 * 		...args: Parameters<AuthenticationIdentity['loginAfterGoogleSignUp']>
 * 	) => ReturnType<AuthenticationIdentity['loginAfterGoogleSignUp']>,
 *  loginAsGuest: (
 * 		...args: Parameters<AuthenticationIdentity['loginAsGuest']>
 * 	) => ReturnType<AuthenticationIdentity['loginAsGuest']>,
 *  logout: (...args: Parameters<AuthenticationIdentity['logout']>) => void,
 *  loginChallengeSelectOrganization: (
 *  	...args: Parameters<AuthenticationIdentity['selectOrganization']>
 *  ) => ReturnType<AuthenticationIdentity['selectOrganization']>,
 *  cancelLoginChallengeSelectOrganization: () => void,
 *  refreshSession: () => Promise<void>,
 *  switchTokens: (tokens: import('../../lib/Identity').Tokens) => void,
 *  storePreviousSession: () => void,
 *  restorePreviousSession: () => void,
 * }} IAuthenticationContext
 */

export const AuthenticationContext = createContext(
	/** @type {IAuthenticationContext|undefined} */(undefined),
);

/**
 * @returns {IAuthenticationContext}
 */
export const useAuthentication = () => {
	const authenticationContext = useContext(AuthenticationContext);
	// type guard (removes undefined type)
	if (!authenticationContext) {
		throw new Error('useAuthentication must be used within a AuthenticationProvider');
	}
	return authenticationContext;
};

/**
 * @param {Role} role
 * @returns {boolean}
 */
export const useHasRole = (role) => {
	const authentication = useAuthentication();
	return !!authentication.isLoggedIn && authentication.user?.role === role;
};

const SevereError = () => (
	<section className="d-flex justify-content-center align-items-center w-100 h-100 p-4">
		<Alert color="danger">Server error, please retry in a few minutes</Alert>
	</section>
);

/**
 * @param {User} user
 * @returns {boolean}
 */
const isGuest = (user) => user.role === Role.GUEST;

/**
 * @param {AuthenticationState} state
 * @param {import('react').ReducerAction<import('react').Reducer<any, any>>} action
 * @returns {AuthenticationState}
 */
const reducer = (state, action) => {
	switch (action.type) {
	case 'AUTH_PENDING': {
		return {
			...state,
			isErrorSevere: false,
			isPending: true,
		};
	}
	case 'LOGIN': {
		if (isGuest(action.user)) {
			return {
				isGuest: true,
				isLoggedIn: false,
				guest: action.user,
			};
		}

		return {
			isLoggedIn: true,
			user: action.user,
		};
	}
	case 'LOGOUT': {
		return {};
	}
	case 'AUTH_ERROR_SEVERE': {
		return {
			...state,
			isPending: false,
			isErrorSevere: true,
		};
	}
	case 'AUTH_ERROR':
	case 'CANCEL_ORGANIZATION_CHALLENGE': {
		return {
			...state,
			isErrorSevere: false,
			isPending: false,
		};
	}
	default:
		return state;
	}
};

const initAuthentication = () => {
	try {
		const user = identity.getUserSync();
		ReactGA.set({ userId: user.sub });

		if (isGuest(user)) {
			return {
				isGuest: true,
				isLoggedIn: false,
				guest: user,
			};
		}

		return {
			isLoggedIn: true,
			user,
		};
	} catch (error) {
		if (!(error instanceof IdentityError)) {
			throw error;
		}
		return {};
	}
};

/**
 * @typedef {{
 *  children: import('react').ReactNode
 * }} AuthenticationProviderProps
 */

/** @type {import('react').FC<AuthenticationProviderProps>} */
export const AuthenticationProvider = ({ children }) => {
	const [authentication, dispatch] = useReducer(reducer, {}, initAuthentication);
	const queryClient = useQueryClient();

	/** @type {IAuthenticationContext['login']} */
	const login = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.login(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	/** @type {IAuthenticationContext['loginChallengeSelectOrganization']} */
	const loginChallengeSelectOrganization = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.selectOrganization(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	/** @type {IAuthenticationContext['loginAfterGoogleSignUp']} */
	const loginAfterGoogleSignUp = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.loginAfterGoogleSignUp(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	/** @type {IAuthenticationContext['loginAsGuest']} */
	const loginAsGuest = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.loginAsGuest(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR_SEVERE' });
			throw error;
		}
	}, []);

	/** @type {IAuthenticationContext['switchTokens']} */
	const switchTokens = useCallback(async (tokens) => {
		identity.loginWithTokens(tokens);
	}, []);

	/** @type {IAuthenticationContext['storePreviousSession']} */
	const storePreviousSession = useCallback(() => {
		identity.setPreviousSession();
	}, []);

	/** @type {IAuthenticationContext['restorePreviousSession']} */
	const restorePreviousSession = useCallback(() => {
		const previousSession = identity.getPreviousSession();

		switchTokens({
			access_token: previousSession.access_token.token,
			id_token: previousSession.id_token.token,
			// TODO: is it used ?
			userId: previousSession.access_token.payload.sub,
		});

		identity.clearPreviousSession();
	}, [switchTokens]);

	const cancelLoginChallengeSelectOrganization = useCallback(() => {
		dispatch({ type: 'CANCEL_ORGANIZATION_CHALLENGE' });
	}, []);

	/** @type {IAuthenticationContext['logout']} */
	const logout = useCallback((...args) => {
		try {
			identity.logout(...args);
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	/** @type {IAuthenticationContext['refreshSession']} */
	const refreshSession = useCallback(async () => {
		try {
			await identity.refreshSession();
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const { isErrorSevere, isPending } = authentication;
	const isSessionActive = !!(
		!isErrorSevere
		&& (authentication.isLoggedIn || authentication.isGuest)
	);
	const shouldRequestGuestSession = !isSessionActive && !isPending && !isErrorSevere;

	const contextValue = useMemo(() => ({
		...authentication,
		isSessionActive,
		login,
		loginAfterGoogleSignUp,
		loginAsGuest,
		logout,
		loginChallengeSelectOrganization,
		cancelLoginChallengeSelectOrganization,
		refreshSession,
		switchTokens,
		storePreviousSession,
		restorePreviousSession,
	}), [
		authentication,
		isSessionActive,
		login,
		loginAfterGoogleSignUp,
		loginAsGuest,
		logout,
		loginChallengeSelectOrganization,
		cancelLoginChallengeSelectOrganization,
		refreshSession,
		switchTokens,
		storePreviousSession,
		restorePreviousSession,
	]);

	useEffect(() => {
		const onLogin = () => {
			try {
				const user = identity.getUserSync();
				ReactGA.set({ userId: user.sub });
				dispatch({
					type: 'LOGIN',
					user,
				});
			} catch (error) {
				// TODO: use debug
				// eslint-disable-next-line no-console
				console.error(error);
			}
		};

		const onLogout = () => {
			dispatch({ type: 'LOGOUT' });
		};

		identity.on('login', onLogin);
		identity.on('logout', onLogout);
		identity.on('refresh', onLogin);

		return () => {
			identity.off('login', onLogin);
			identity.off('logout', onLogout);
			identity.off('refresh', onLogin);
		};
	}, [queryClient]);

	const isInitialRender = useRef(true);
	const userOrGuest = authentication.user || authentication.guest;

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}
		/**
		 * Invalidate queries when user or guest changes :
		 * - login
		 * - logout
		 * - refresh
		 * Dont need to invalidate after first render as the user is retrieved in sync
		 */
		queryClient.invalidateQueries();
	}, [queryClient, userOrGuest]);

	useEffect(() => {
		if (shouldRequestGuestSession) {
			/**
			 * When the session is not active or pending, login as guest
			 */
			loginAsGuest();
		}
	}, [shouldRequestGuestSession, loginAsGuest]);

	return (
		<AuthenticationContext.Provider value={contextValue}>
			{/* Session must be establised before rendering the app (guest or user)  */}
			{isSessionActive ? children : null}
			{isErrorSevere ? <SevereError /> : null}
		</AuthenticationContext.Provider>
	);
};

AuthenticationProvider.propTypes = {
	children: PropTypes.node,
};

AuthenticationProvider.defaultProps = {
	children: undefined,
};
