import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { FaGift } from 'react-icons/fa';

import { Button } from 'reactstrap';
import { PublicPathPrefix, Path, getLink } from '../../RoutePath';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { ButtonPill } from '../../components/Button';
import { LanguageSelector } from '../../components/Language/LanguageSelector';
import { SearchInput } from '../../components/SearchSuggestion/SearchInput';
import { StudioHeader } from '../../components/Studio/Header';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import { PREVIOUS_SESSION_KEY } from '../../lib/Identity';
import { useHandleDeleteDisableSubscription } from '../../lib/serviceWorkerHook';
import HeaderAnonymousBox from '../HeaderAnonymousBox';
import HeaderUserbox from '../HeaderUserbox';
import SidebarHeader from '../SidebarHeader';
import { useHeaderSearch } from './SearchContext';
import { useProfile } from '../../components/Profile/ProfileContext';
import { RolePaymentType } from '../../components/OrganizationSettings/Role/RoleForm';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { StudioFullscreenHeaderStatusBadge } from '../../components/Studio/StudioHeaderStatusBadge';
import { StudioStatusProvider } from '../../components/Studio/Status/Provider';
import { PlayerControlsDisplayProvider } from '../../components/Player/ControlsDisplay/Provider';
import { UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';

import './StudioHeader.scss';
import { MembershipModalTab } from '../../components/UserSettings/MembershipModalTab';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const Header = ({
	isStudioRoute,
	search,
}) => {
	const { isLoggedIn, restorePreviousSession } = useAuthentication();
	const { currentModeScreen } = useScreenMode();
	const navigate = useNavigate();
	const deleteSubscription = useHandleDeleteDisableSubscription();
	const { t } = useTranslation();

	const { profile } = useProfile();

	const { openModal } = useUserSettings();

	const {
		currentStudio,
	} = useCurrentStudio();

	const { isSearchOpen, setSearchOpen } = useHeaderSearch();

	const isOrgPublicChannel = !!localStorage.getItem(PREVIOUS_SESSION_KEY);

	const handleGoBackToOrganization = useCallback(async () => {
		restorePreviousSession();

		await deleteSubscription();
		navigate('/');
	}, [deleteSubscription, navigate, restorePreviousSession]);

	const isHomeRoute = useMatch({ path: '/', end: true });
	const isWelcome = useMatch({ path: Path.NEWUSERINFO, end: false });
	const isPrices = useMatch({ path: Path.PRICES });
	const isVideos = useMatch({ path: `${PublicPathPrefix}/videos` }); // TODO: Replace with a constant when route available
	const isFullscreen = currentModeScreen === ModeScreenLayout.FULLSCREEN;
	const isStudioInFullscreen = isFullscreen && isStudioRoute;

	const showLanguageSelector = isHomeRoute || isWelcome || isVideos || isPrices;

	const handleOpenDonationModal = useCallback(() => {
		openModal(
			UserSettingsModal.MEMBERSHIP,
			MembershipModalTab.DONATION,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			currentStudio.owner,
		);
	}, [openModal, currentStudio]);

	const showDonationToOrgOwner = useMemo(() => {
		if (!profile?.organization.default && !profile?.organizationOwner) {
			return profile?.organizationRole?.paymentType === RolePaymentType.PAID;
		}

		return false;
	},
	[profile]);

	const showDonationToStudioOwner = currentStudio?.owner
	&& profile?._id !== currentStudio?.owner._id && isLoggedIn && isStudioRoute;

	const isHostRoute = useMatch({
		path: getLink(
			Path.STUDIO_HOST,
			{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
		),
		end: false,
	});
	const isOperatorRoute = useMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });

	const isController = isHostRoute || isOperatorRoute;

	return (
		<PlayerControlsDisplayProvider
			enabled={isStudioInFullscreen}
			target=".studio-fullscreen"
		>
			<div
				className={clsx('d-block', { 'StudioFullscreenHeader position-absolute w-100 layout-fullscreen': isStudioInFullscreen }, { 'd-none': (!isStudioRoute && isFullscreen) })}
				id="Header"
			>
				<div className={clsx('app-header d-flex', { StudioFullscreenHeader: isStudioInFullscreen, 'app-header--shadow': !isStudioInFullscreen })}>
					<div
						className={clsx('LogoBeeyouContainer d-flex align-items-center flex-shrink-0',
							{ 'search-menu-open': isSearchOpen && !isStudioRoute },
							{ 'search-menu-close': !isSearchOpen && !isStudioRoute },
							{ 'w-auto': isStudioRoute },
							{ 'text-white': isStudioInFullscreen })}
					>
						<SidebarHeader
							isStudioRoute={isStudioRoute}
							isSearchOpen={isSearchOpen}
						/>
						{isLoggedIn && (showDonationToOrgOwner || showDonationToStudioOwner) && isBeeyou && (
							<Button
								className="ml-3 px-2 d-none d-xl-block"
								color="primary"
								type="button"
								size="sm"
								onClick={handleOpenDonationModal}
							>
								<span className="btn-wrapper--icon"><FaGift /></span>
							</Button>
						)}
					</div>
					{!isStudioRoute && search && (isLoggedIn || isBeeyou) && (
						<div
							className={clsx(
								'd-flex align-items-center h-100 flex-grow-1 flex-shrink-1 min-w-0',
								{
									'mr-2': !isSearchOpen,
									'mr-md-2': isSearchOpen,
								},
							)}
						>
							<SearchInput
								isSearchOpen={isSearchOpen}
								setSearchOpen={setSearchOpen}
							/>
						</div>
					)}
					{isStudioRoute && (
						<div className="d-flex align-items-center justify-content-center h-100 flex-grow-1 flex-shrink-1 min-w-0">
							<StudioStatusProvider studio={currentStudio}>
								<StudioFullscreenHeaderStatusBadge
									showDonationToStudioOwner={showDonationToStudioOwner}
									toggleDonateModal={handleOpenDonationModal}
									isController={!!isController}
								/>
							</StudioStatusProvider>
						</div>
					)}
					<StudioHeader isSearchOpen={isSearchOpen} />
					<div className="d-flex align-items-center h-100">
						{!!isLoggedIn && isOrgPublicChannel && (
							<ButtonPill
								color="primary"
								size="sm"
								className="mr-3"
								onClick={handleGoBackToOrganization}
							>
								{t('HeaderUserbox.Index.switchToOrganization')}
							</ButtonPill>
						)}
						<div
							className={clsx('d-flex flex-shrink-0 flex-wrap align-items-center justify-content-center h-100',
								{ 'search-menu-open': isSearchOpen && !isStudioRoute },
								{ 'search-menu-close': !isSearchOpen && !isStudioRoute })}
						>
							{isLoggedIn
								? <HeaderUserbox isFullscreen={isStudioInFullscreen} />
								: <HeaderAnonymousBox />}
						</div>
					</div>
					{showLanguageSelector && (
						<LanguageSelector
							className={clsx('h-50 pb-2 pt-1 me-2 ml-2',
								{ 'd-none': isSearchOpen })}
							minimal
						/>
					)}
				</div>
			</div>
		</PlayerControlsDisplayProvider>
	);
};

Header.propTypes = {
	isStudioRoute: PropTypes.bool,
	search: PropTypes.bool,
};

Header.defaultProps = {
	isStudioRoute: false,
	search: false,
};

export default Header;
