// @ts-check
/* eslint-disable react/prop-types */

import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Popover, PopoverBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../lib/time';

/**
 * @typedef {{
 *  itemId: string,
 *  createdAt: Date,
 * }} DateInfoPopoverProps
 */

export const DateInfoPopover = (
/** @type {DateInfoPopoverProps} */
	{ itemId, createdAt },
) => {
	const { t } = useTranslation();

	const [showPopover, setShowPopover] = useState(false);

	return (
		<>
			<span
				onMouseEnter={() => setShowPopover(true)}
				onMouseLeave={() => setShowPopover(false)}
				id={`DateInfoIcon-${itemId}`}
			>
				<FaInfoCircle size={12} className="ml-1" />
			</span>
			<Popover
				placement="left"
				isOpen={showPopover}
				target={`DateInfoIcon-${itemId}`}
				trigger="hover"
				popperClassName="position-absolute"
				offset={[-10, 0]}
			>
				<PopoverBody className="text-black">
					<p className="mb-0">{formatDate(createdAt, t)}</p>
				</PopoverBody>
			</Popover>
		</>
	);
};
