// @ts-check

/**
 * @param {number} time
 * @returns {string}
 */
export const displayTime = (time) => {
	const h = Math.floor(time / 3600);
	const m = Math.floor((time - h * 3600) / 60);
	const s = Math.floor(time - h * 3600 - m * 60);

	let timeToDisplay = h > 0 ? (`${h.toString().padStart(2, '0')}:`) : '';
	timeToDisplay += `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
	return timeToDisplay;
};
