// @ts-check

import countries from 'i18n-iso-countries';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesEs from 'i18n-iso-countries/langs/es.json';
import countriesFr from 'i18n-iso-countries/langs/fr.json';
import countriesIt from 'i18n-iso-countries/langs/it.json';

countries.registerLocale(countriesDe);
countries.registerLocale(countriesEn);
countries.registerLocale(countriesEs);
countries.registerLocale(countriesFr);
countries.registerLocale(countriesIt);

/**
 * @param {string} languageKey
 * @returns {import('i18n-iso-countries').LocalizedCountryNames<{ select: 'official' }>}
 */
export const getCountriesTranslations = (languageKey) => {
	if (['de', 'en', 'es', 'fr', 'it'].includes(languageKey)) {
		return countries.getNames(languageKey);
	}
	return countries.getNames('en');
};
