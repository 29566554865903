import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

export const ResourceType = {
	CHANNEL: 'CHANNEL',
	STUDIO: 'STUDIO',
	VIDEO: 'VIDEO',
	AD_CAMPAIGN: 'AD_CAMPAIGN',
	FOLDER: 'FOLDER',
};

export const getRoleField = (role) => {
	switch (role) {
	case ResourceAccessRole.PARTICIPANT:
		return 'participant';
	case ResourceAccessRole.VIEWER:
		return 'viewer';
	default:
		throw new TypeError(`Invalid role ${role}`);
	}
};

export const getResourceLabel = (resourceType) => {
	switch (resourceType) {
	case ResourceType.CHANNEL:
		return 'Channel';
	case ResourceType.STUDIO:
		return 'Studio';
	case ResourceType.VIDEO:
		return 'Video';
	case ResourceType.AD_CAMPAIGN:
		return 'Ad Campaign';
	case ResourceType.FOLDER:
		return 'Folder';
	default:
		return '';
	}
};
