/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';

import 'video.js/dist/video-js.css';

/**
 * @typedef {{
 *  className?: string,
 * }} HLSPlayerScreenLoadingProps
 */

/** @type {React.FC<HLSPlayerScreenLoadingProps>} */
export const HLSPlayerScreenLoading = ({
	className = '',
}) => (
	<div className={clsx('position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center video-js vjs-waiting', className)}>
		<div className="vjs-loading-spinner" dir="ltr">
			<span className="vjs-control-text">Video Player is loading.</span>
		</div>
	</div>
);
