import { useCallback } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';

export const DonatePoints = ({ withBackground, channel }) => {
	const { t } = useTranslation();

	const { openModal } = useUserSettings();

	const handleOpenDonationModal = useCallback(() => {
		openModal(
			UserSettingsModal.MEMBERSHIP,
			MembershipModalTab.DONATION,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			channel,
		);
	}, [openModal, channel]);

	return (
		<div className={`d-flex align-items-center justify-content-center ${withBackground ? 'bg-primary py-2' : 'h-100'}`}>
			<p
				className={`m-0 p-0 ${!withBackground ? 'text-primary' : ''}`}
				style={withBackground ? { color: '#070919' } : {}}
			>
				{t('DonatePoints.studioAboutToExpire')}
			</p>
			<Button
				onClick={handleOpenDonationModal}
				color={!withBackground ? 'primary' : 'dark'}
				className="flex-shrink-0 mx-3"
			>
				<span className="btn-wrapper--label d-md-inline">
					{t('DonatePoints.donatePoints')}
				</span>
			</Button>
		</div>
	);
};

DonatePoints.propTypes = {
	withBackground: PropTypes.bool,
	channel: PropTypes.shape({}),
};
DonatePoints.defaultProps = {
	withBackground: false,
	channel: undefined,
};
