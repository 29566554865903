/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from '../Player/helpers';
import { getThumbnail, getThumbnailGif } from '../../lib/file';
import { useValidateImage } from '../../lib/useValidateImage';

import './Thumbnail.scss';

/**
 * @import { IVideoPublicDto } from '../../api/channel/videos.dto';
 * @import { ESVodResult } from '../../api/elasticsearch';
 */

/**
 * @typedef {{
 * 	absoluteChildren?: React.ReactNode,
 * 	className?: string,
 * 	duration?: number,
 * 	onThumbnailLoaded?: (element: HTMLImageElement | HTMLVideoElement) => void,
 * 	video: IVideoPublicDto | ESVodResult,
 * }} VideoThumbnailProps
 */

export const VideoThumbnail = (
	/** @type {VideoThumbnailProps} */
	{
		absoluteChildren,
		className = '',
		duration,
		onThumbnailLoaded,
		video,
	},
) => {
	const { t } = useTranslation();

	const canViewAnimatedThumbnail = !!video.muxTokens?.gif;
	const [showAnimatedThumbnail, setShowAnimatedThumbnail] = useState(false);
	const [animatedThumbnailClassName, setAnimatedThumbnailClassName] = useState('');
	const animatedThumbnailSrc = getThumbnailGif(video);
	const thumbnailContainerRef = useRef(/** @type {HTMLDivElement | null} */(null));

	const [videoDuration, setVideoDuration] = useState(0);
	const validateImage = useValidateImage();

	const handleThumbnailLoaded = useCallback((
		/** @type {React.SyntheticEvent<(HTMLImageElement | HTMLVideoElement), Event>} */e,
	) => {
		if (onThumbnailLoaded
			&& (e.target instanceof HTMLImageElement || e.target instanceof HTMLVideoElement)) {
			onThumbnailLoaded(e.target);
		}
	}, [onThumbnailLoaded]);

	const handleLoadedMetadata = useCallback((
		/** @type {React.SyntheticEvent<HTMLVideoElement>} */event,
	) => {
		setVideoDuration(event.currentTarget.duration);
	}, []);

	const durationValue = Math.floor(duration ?? ('duration' in video ? video.duration : undefined) ?? videoDuration);
	const src = getThumbnail(video);
	const isVideo = video.status !== 'ready';

	// useEffect to detect when user hovers the thumbnail to show the animated thumbnail
	useEffect(() => {
		if (!canViewAnimatedThumbnail || !thumbnailContainerRef.current) return undefined;

		const thumbnail = thumbnailContainerRef.current;
		/** @type {NodeJS.Timeout | undefined} */
		let timeout;

		const handleMouseEnter = () => {
			timeout = setTimeout(() => {
				setShowAnimatedThumbnail(true);
			}, 500);
		};

		const handleMouseLeave = () => {
			clearTimeout(timeout);
			setShowAnimatedThumbnail(false);
		};

		thumbnail.addEventListener('mouseenter', handleMouseEnter);
		thumbnail.addEventListener('touchstart', handleMouseEnter);
		thumbnail.addEventListener('mouseleave', handleMouseLeave);
		thumbnail.addEventListener('touchend', handleMouseLeave);
		thumbnail.addEventListener('touchcancel', handleMouseLeave);

		return () => {
			thumbnail.removeEventListener('mouseenter', handleMouseEnter);
			thumbnail.removeEventListener('touchstart', handleMouseEnter);
			thumbnail.removeEventListener('mouseleave', handleMouseLeave);
			thumbnail.removeEventListener('touchend', handleMouseLeave);
			thumbnail.removeEventListener('touchcancel', handleMouseLeave);
		};
	}, [canViewAnimatedThumbnail, thumbnailContainerRef]);

	return (
		<RatioContainer
			className={
				clsx(
					'VideoThumbnail',
					className,
					{ 'position-relative': !!absoluteChildren },
				)
			}
		>
			<div className="VideoThumbnail_container" ref={thumbnailContainerRef}>
				{showAnimatedThumbnail && (
					<LazyLoadImage
						alt={t('Video.Thumbnail.videoAnimatedThumbnail')}
						beforeLoad={() => setAnimatedThumbnailClassName('d-none')}
						className={animatedThumbnailClassName}
						crossOrigin="anonymous"
						onError={() => setShowAnimatedThumbnail(false)}
						onLoad={() => setAnimatedThumbnailClassName('')}
						src={animatedThumbnailSrc}
					/>
				)}
				{isVideo ? (
					<video
						crossOrigin="anonymous"
						muted
						onLoadedMetadata={handleLoadedMetadata}
						src={src}
						onLoadedData={(e) => {
							if (!(e.target instanceof HTMLVideoElement)) return;

							// When the video is loaded, we set the currentTime to 0.001 to trigger the
							// onTimeUpdate event. This ensure that the thumbnail is displayed when
							// calling handleThumbnailLoaded.
							e.target.currentTime = 0.001;
						}}
						onTimeUpdate={handleThumbnailLoaded}
					/>
				) : (
					<LazyLoadImage
						alt={t('Video.Thumbnail.videoThumbnail')}
						crossOrigin="anonymous"
						onError={validateImage}
						onLoad={handleThumbnailLoaded}
						src={src}
					/>
				)}
				{
					!Number.isNaN(durationValue)
					&& Number.isFinite(durationValue)
					&& durationValue > 0
					&& (
						<span className="Player_duration">
							{minutes(durationValue)}:{seconds(durationValue)}
						</span>
					)
				}
			</div>
			{absoluteChildren}
		</RatioContainer>
	);
};
