import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputGroup, Row, TabPane } from 'reactstrap';

import { ResourceVisibility } from '../../../lib/ResourceVisibility';
import { useCreateShareableLink, useDeleteShareableLink } from '../../../api-hooks/channel/videos';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRoleInput } from '../RoleInput';
import { getShareLink, ShareTabType } from '../Share.helper';
import { AlertTimeout } from '../../Alert/Timeout';

export const ShareTabLink = ({
	channel,
	inputColor,
	post,
	roles,
	selectedRole,
	setSelectedRole,
	studio,
	video,
}) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [privateShareId, setPrivateShareId] = useState(video?.privateShareId);
	const [link, setLink] = useState(
		getShareLink({ channel, studio, video, role: selectedRole, post }),
	);

	// Reset link when selected role changes
	useEffect(() => {
		setLink(getShareLink({ channel, studio, video, role: selectedRole, post }));
	}, [channel, studio, video, selectedRole, post]);

	const { t } = useTranslation();

	const { mutate: createShareableLink } = useCreateShareableLink();
	const { mutate: deleteShareableLink } = useDeleteShareableLink();

	const handleCopyLink = useCallback(() => {
		navigator.clipboard.writeText(link)
			.then(() => {
				setSuccess(true);
				setTimeout(() => setSuccess(false), 3000);
			}, () => {
				setError(true);
				setTimeout(() => setError(false), 3000);
			});
	}, [link]);

	const handleCreateShareableLink = useCallback(() => {
		createShareableLink(video?._id, {
			onSuccess: (data) => {
				setPrivateShareId(data);
				setLink(getShareLink(
					{ channel, studio, video, role: selectedRole, post, privateShareId: data },
				));
			},
		});
	}, [createShareableLink, video, channel, studio, selectedRole, post, setPrivateShareId, setLink]);

	const handleDeleteShareableLink = useCallback(() => {
		deleteShareableLink(video?._id, {
			onSuccess: () => {
				setPrivateShareId('');
			},
		});
	}, [deleteShareableLink, video?._id, setPrivateShareId]);

	const isPrivateWithoutShareId = video?.visibility === ResourceVisibility.JUST_ME
	&& !privateShareId;

	const isPrivateWithShareId = video?.visibility === ResourceVisibility.JUST_ME
	&& privateShareId;

	return isPrivateWithoutShareId
		? (
			<div className="d-flex flex-column justify-content-center align-items-center">
				<div className="mb-3 fw-bold">{t('Share.Tab.Link.privateVideo')}</div>
				<Button
					color="primary"
					className="btn-pill fw-bold"
					onClick={handleCreateShareableLink}
				>
					{t('Share.Tab.Link.createShareableLink')}
				</Button>
			</div>
		)
		: (
			<TabPane tabId="Link">
				<Row className={`g-0 form-${inputColor} h-100 w-100 d-flex justify-content-center align-items-center`}>
					<h4 className="text-center pt-2 pb-2 font-weight-bold mt-3">
						{t('Share.Tab.Link.youCanCopyLinkAndPasteIt')}
					</h4>
					{isPrivateWithShareId && (
						<p className="text-center">{t('Share.Tab.Link.shareLinkMessage')}</p>
					)}
					<InputGroup className="mb-3">
						<Input
							className={`text-truncate border-${inputColor} bg-${inputColor}`}
							type="url"
							name="url"
							id="url"
							value={link}
							readOnly
							onClick={handleCopyLink}
						/>
					</InputGroup>
					{success && (
						<AlertTimeout color="success" onTimeout={() => setSuccess(false)} timeoutTime={3000}>
							{t('Share.Tab.Link.linkCopied')}
						</AlertTimeout>
					)}
					{error && (
						<AlertTimeout color="danger" onTimeout={() => setError(false)}>
							{t('Share.Tab.Link.somethingWentWrong')}
						</AlertTimeout>
					)}
					<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
						<ShareRoleInput
							value={selectedRole}
							onChange={(role) => setSelectedRole(role)}
							roles={roles}
							shareTabType={ShareTabType.LINK}
						/>
						<div className="mb-3 w-100 d-flex justify-content-between">
							<Button
								color="primary"
								className="btn-pill shadow-none font-weight-bold"
								onClick={handleCopyLink}
							>
								{t('Share.Tab.Link.copyLink')}
							</Button>
							{isPrivateWithShareId && (
								<Button
									color="danger"
									className="btn-pill fw-bold"
									onClick={handleDeleteShareableLink}
								>
									{t('Share.Tab.Link.deleteShareableLink')}
								</Button>
							)}
						</div>
					</div>
				</Row>
			</TabPane>
		);
};

ShareTabLink.propTypes = {
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	inputColor: PropTypes.oneOf(['dark', 'light']),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		visibility: PropTypes.string,
		privateShareId: PropTypes.string,
	}),
	post: PropTypes.shape({}),
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
};

ShareTabLink.defaultProps = {
	channel: undefined,
	inputColor: 'dark',
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
};
