// @ts-check

import { api } from '../api';

export const API_PUBLIC_IMAGE_PATH = '/public/image';

/** @typedef {import('../channel/images.dto').IImageDto} IImageDto */

/**
 * @param {string} [channelId]
 * @param {string} [folderId]
 * @param {boolean} [ignoreFolders]
 * @returns {Promise<import('axios').AxiosResponse<IImageDto[]>>}
 */

export const fetchAllPublicImagesByChannelId = (channelId, folderId, ignoreFolders) => api.get(
	`${API_PUBLIC_IMAGE_PATH}/byChannel/${channelId}/all`,
	{ params: { folderId, ignoreFolders } },
);
