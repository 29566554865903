import { getConnect, getDisconnect, getSocket } from '@technomiam/wsapi-client';

const { VITE_WSAPI_URL } = import.meta.env;
const GLOBAL_URL = `${VITE_WSAPI_URL}/global`;

export const connectSockets = getConnect(GLOBAL_URL);
export const disconnectSockets = getDisconnect(GLOBAL_URL);
export const socket = getSocket(GLOBAL_URL);

export const onEventNotification = (listener) => {
	const sock = socket();
	sock.on('event.notification', listener);
	return () => {
		sock.off('event.notification', listener);
	};
};

export const onEventNotificationSystemNew = (listener) => {
	const sock = socket();
	sock.on('event.notification.system.new', listener);
	return () => {
		sock.off('event.notification.system.new', listener);
	};
};

export const onEventNotificationStudioCharge = (listener) => {
	const sock = socket();
	sock.on('event.notification.studio.charge', listener);
	return () => {
		sock.off('event.notification.studio.charge', listener);
	};
};

export const onEventConversationNew = (listener) => {
	const sock = socket();
	sock.on('event.conversation.new', listener);
	return () => {
		sock.off('event.conversation.new', listener);
	};
};

export const onEventConversationDelete = (listener) => {
	const sock = socket();
	sock.on('event.conversation.delete', listener);
	return () => {
		sock.off('event.conversation.delete', listener);
	};
};

export const onEventConversationUpdate = (listener) => {
	const sock = socket();
	sock.on('event.conversation.update', listener);
	return () => {
		sock.off('event.conversation.update', listener);
	};
};

export const onEventConversationMessageNew = (listener) => {
	const sock = socket();
	sock.on('event.conversation.message.new', listener);
	return () => {
		sock.off('event.conversation.message.new', listener);
	};
};

export const onEventConversationMessageDelete = (listener) => {
	const sock = socket();
	sock.on('event.conversation.message.delete', listener);
	return () => {
		sock.off('event.conversation.message.delete', listener);
	};
};

export const onEventConversationMessageUpdateAll = (listener) => {
	const sock = socket();
	sock.on('event.conversation.message.update.all', listener);
	return () => {
		sock.off('event.conversation.message.update.all', listener);
	};
};

export const onEventConversationMessageUpdate = (listener) => {
	const sock = socket();
	sock.on('event.conversation.message.update', listener);
	return () => {
		sock.off('event.conversation.message.update', listener);
	};
};

export const onEventConversationUnreadCounts = (listener) => {
	const sock = socket();
	sock.on('event.conversation.unreadCounts', listener);
	return () => {
		sock.off('event.conversation.unreadCounts');
	};
};

export const onEventConversationUnreadCountsNew = (listener) => {
	const sock = socket();
	sock.on('event.conversation.unreadCounts.new', listener);
	return () => {
		sock.off('event.conversation.unreadCounts.new');
	};
};

export const onEventConnectionStatus = (listener) => {
	const sock = socket();
	sock.on('event.connection.status', listener);
	return () => {
		sock.off('event.connection.status', listener);
	};
};

export const onEventVideoNew = (listener) => {
	const sock = socket();
	sock.on('event.video.new', listener);
	return () => {
		sock.off('event.video.new', listener);
	};
};

export const onEventVideoStatusChanged = (listener) => {
	const sock = socket();
	sock.on('event.video.status.changed', listener);
	return () => {
		sock.off('event.video.status.changed', listener);
	};
};

export const onEventStudioActiveStatus = (listener) => {
	const sock = socket();
	sock.on('event.studio.active.status', listener);
	return () => {
		sock.off('event.studio.active.status', listener);
	};
};

export const onEventBalanceChange = (listener) => {
	const sock = socket();
	sock.on('event.balance.change', listener);
	return () => {
		sock.off('event.balance.change', listener);
	};
};

export const onEventModerateDeactivate = (listener) => {
	const sock = socket();
	sock.on('event.admin.deactivate', listener);
	return () => {
		sock.off('event.admin.deactivate', listener);
	};
};

export const joinGlobal = () => {
	const sock = socket();

	const onReconnect = () => sock.emit('join.global');
	sock.on('reconnect', onReconnect);

	sock.emit('join.global');
	return () => {
		sock.off('reconnect', onReconnect);
		sock.emit('leave.global');
	};
};

export const onEventFriendRequestStatusChanged = (listener) => {
	const sock = socket();
	sock.on('event.friend.request.change', listener);
	return () => {
		sock.off('event.friend.request.change');
	};
};

export const onEventFriendRequestNew = (listener) => {
	const sock = socket();
	sock.on('event.friend.request.new', listener);
	return () => {
		sock.off('event.friend.request.new');
	};
};

export const onEventStreamChange = (listener) => {
	const sock = socket();
	sock.on('event.stream.change', listener);
	return () => {
		sock.off('event.stream.change', listener);
	};
};
