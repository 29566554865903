// @ts-check

/** @enum {string} */
export const NotificationType = {
	DONATION_THANK_YOU: 'donation_thank_you',
	DONATION: 'donation',
	FOLDER_PURCHASE_SUCCESS: 'folder_purchase_success',
	INFORMATION: 'information',
	PUBLIC_STUDIO_START_SOON: 'public_studio_start_soon',
	SPONSOR_AD: 'sponsor_ad',
	STUDIO_ENDING: 'studio_ending',
	STUDIO_HOST_REMINDER: 'studio_host_reminder',
	STUDIO_IDLE: 'studio_idle',
	STUDIO_INVITE_STARTED: 'studio_invite_started',
	STUDIO_INVITE_SCHEDULED: 'studio_invite_scheduled',
	VIDEO: 'video',
};
