import { NotificationType } from '../../lib/NotificationType';
import { getWatchLink } from '../../RoutePath';

export const NotificationAction = {
	PROMOTE_TO_PARTICIPANT: 'PROMOTE_TO_PARTICIPANT',
	DEMOTE_TO_VIEWER: 'DEMOTE_TO_VIEWER',
};

const promoteToParticipant = ({ notification, t }) => {
	const translationData = {
		nickname: notification.sender?.nickname,
		subject: notification.data.subject,
	};

	notification.skipAd = true;
	notification.message = t('Notification.Toast.studioInvite.started.PARTICIPANT', translationData);
};

const demoteToViewer = ({ notification, navigate }) => {
	notification.message = 'Notification.Toast.youHaveBeenRemovedAsAParticipant';
	notification.type = NotificationType.INFORMATION;
	navigate(getWatchLink(notification.data.channel.hashtag, notification.data.watchLinkId));
};

export const NotificationActionHandler = {
	[NotificationAction.PROMOTE_TO_PARTICIPANT]: (params) => promoteToParticipant(params),
	[NotificationAction.DEMOTE_TO_VIEWER]: (params) => demoteToViewer(params),
};
