// @ts-check

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';
import { useDeviceStatusFromConfig } from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';
import { ButtonLoadingIcon } from '../../../Button';

/**
 * @typedef {object & {
 * 	className?: string,
 * 	configId?: number,
 * }} LocalCamProps
 */

export const LocalCam = (
	/** @type {LocalCamProps} */
	{ className, configId = 0, ...props },
) => {
	const {
		device,
		error,
		isActive,
		isLoading,
		permission,
		toggleInputDevice,
	} = useDeviceStatusFromConfig(configId, 'videoinput');

	let shownError = error;
	if (!device) {
		shownError = new Error('Device not found');
	}
	if (permission === 'denied') {
		shownError = new Error('Permission denied');
	}

	let color = 'primary';
	if (shownError) {
		color = 'neutral-danger';
	} else if (!isActive) {
		color = 'neutral-primary';
	}

	const animatedIconColor = shownError ? 'danger' : 'primary';

	return (
		<>
			<ButtonLoadingIcon
				id="LocalCam"
				tag="span"
				className={clsx(
					'LocalCam btn-animated-icon btn-transition-none',
					`btn-animated-icon--${animatedIconColor} content-${animatedIconColor}`,
					className,
				)}
				color={color}
				loading={isLoading}
				onClick={toggleInputDevice}
				{...props}
			>
				<span className="btn-wrapper--icon d-inline-block">
					{!isActive
						? <FaVideoSlash size={20} />
						: <FaVideo size={20} />}
				</span>
			</ButtonLoadingIcon>
			<MediaErrorPopover error={shownError} target="LocalCam" type="camera" />
		</>
	);
};

LocalCam.propTypes = {
	className: PropTypes.string,
	configId: PropTypes.number,
};

LocalCam.defaultProps = {
	className: '',
	configId: 0,
};
