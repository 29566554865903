/* eslint-disable react/prop-types */
// @ts-check

import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async-hook';

import { fetchMuxStoryboard } from '../../../api/mux';
import { displayTime } from './helper';

/**
 * @import { MuxStoryboard } from '../../../api/mux';
 */

// The height will be calculated based on the 16:9 aspect ratio
const PREVIEW_WIDTH = 160;

/**
 * @typedef {{
 *  controlsRef: React.MutableRefObject<HTMLDivElement | null>,
 *  duration: number,
 * 	playbackId: string,
 * 	storyboardToken: string,
 * }} HLSPlayerVideoTimelinePreviewProps
 */

/** @type {React.FC<HLSPlayerVideoTimelinePreviewProps>} */
export const HLSPlayerVideoTimelinePreview = ({
	controlsRef,
	duration,
	playbackId,
	storyboardToken,
}) => {
	const [currentHoverTime, setCurrentHoverTime] = useState(0);
	const [showPreview, setShowPreview] = useState(false);
	const [storyboardProperties, setStoryboardProperties] = useState({
		scaleFactor: 1,
		totalHeight: 0,
		totalWidth: 0,
	});

	const storyboard = useAsync(async () => {
		const result = await fetchMuxStoryboard(playbackId, storyboardToken);
		if (!result.data) return result;

		const maxX = Math.max(...result.data.tiles.map((tile) => tile.x));
		const maxY = Math.max(...result.data.tiles.map((tile) => tile.y));
		setStoryboardProperties({
			scaleFactor: PREVIEW_WIDTH / result.data.tile_width,
			totalWidth: maxX + result.data.tile_width,
			totalHeight: maxY + result.data.tile_height,
		});

		return result;
	}, [playbackId, storyboardToken]);

	/**
	 * @type {{
	 * 	position: number,
	 *  currentTile?: MuxStoryboard['tiles'][number],
	 * }}
	 * */
	const previewProperties = useMemo(() => {
		if (!controlsRef?.current) return { position: 0 };

		const timelineTrack = controlsRef.current.querySelector('.main');
		if (!timelineTrack) return { position: 0 };

		// Get offset with the complete controls bar to let the preview go above the
		// available space on the right and left of the timeline track
		const timelineTrackRect = timelineTrack.getBoundingClientRect();
		const controlsRect = controlsRef.current.getBoundingClientRect();
		const leftOffset = controlsRect.left - timelineTrackRect.left;
		const rightOffset = controlsRect.right - timelineTrackRect.right;

		const previewPosition = timelineTrack.clientWidth * (currentHoverTime / duration);
		const boundedPreviewPosition = Math.min(
			timelineTrack.clientWidth + rightOffset - (PREVIEW_WIDTH / 2), // Right bound
			Math.max(leftOffset + (PREVIEW_WIDTH / 2), previewPosition), // Left bound
		);

		// Return early if storyboard is not loaded yet or if error occurred
		if (!storyboard.result?.data?.tiles) return { position: boundedPreviewPosition };

		// Find corresponding tiles in Mux storyboard
		const currentTile = storyboard.result.data.tiles
			.findLast((tile) => tile.start <= currentHoverTime);

		return {
			position: boundedPreviewPosition,
			currentTile,
		};
	}, [storyboard.result?.data?.tiles, controlsRef, duration, currentHoverTime]);

	// react-video-seek-slider does not provide a way to get current information on
	// timeline hovering. So, we need to add our own event listeners to the timeline.
	useEffect(() => {
		if (controlsRef.current) {
			const timelineTrack = controlsRef.current.querySelector('.main');
			if (timelineTrack) {
				const updateHoverTime = (
					/** @type {number} */currentXPosition,
				) => {
					const rect = timelineTrack.getBoundingClientRect();
					const hoverTime = ((currentXPosition - rect.left) / rect.width) * duration;
					setCurrentHoverTime(Math.min(duration, Math.max(0, hoverTime)));
				};

				const handleMouseMove = (
					/** @type {Event} */event,
				) => {
					const mouseEvent = /** @type {MouseEvent} */(event);
					updateHoverTime(mouseEvent.clientX);
				};

				const handleTouchMove = (
					/** @type {Event} */event,
				) => {
					const touchEvent = /** @type {TouchEvent} */(event);
					updateHoverTime(touchEvent.touches[0].clientX);
				};

				timelineTrack.addEventListener('mouseenter', () => setShowPreview(true));
				timelineTrack.addEventListener('touchstart', () => setShowPreview(true));
				timelineTrack.addEventListener('mouseleave', () => setShowPreview(false));
				timelineTrack.addEventListener('touchend', () => setShowPreview(false));
				timelineTrack.addEventListener('touchcancel', () => setShowPreview(false));
				timelineTrack.addEventListener('mousemove', handleMouseMove);
				timelineTrack.addEventListener('touchmove', handleTouchMove);

				return () => {
					timelineTrack.removeEventListener('mouseenter', () => setShowPreview(true));
					timelineTrack.removeEventListener('touchstart', () => setShowPreview(true));
					timelineTrack.removeEventListener('mouseleave', () => setShowPreview(false));
					timelineTrack.removeEventListener('touchend', () => setShowPreview(false));
					timelineTrack.removeEventListener('touchcancel', () => setShowPreview(false));
					timelineTrack.removeEventListener('mousemove', handleMouseMove);
					timelineTrack.removeEventListener('touchmove', handleTouchMove);
				};
			}
		}
		return undefined;
	}, [controlsRef, duration]);

	return showPreview && (
		<div
			className="position-absolute bottom-100"
			style={{ left: previewProperties.position }}
		>
			{storyboard.status === 'success'
				? (
					<div
						className="bg-black rounded d-inline-block"
						style={{
							backgroundImage: `url(${storyboard.result?.data?.url})`,
							backgroundPosition: `-${(previewProperties.currentTile?.x || 0) * storyboardProperties.scaleFactor}px -${(previewProperties.currentTile?.y || 0) * storyboardProperties.scaleFactor}px`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: `${storyboardProperties.totalWidth * storyboardProperties.scaleFactor}px ${storyboardProperties.totalHeight * storyboardProperties.scaleFactor}px`,
							height: PREVIEW_WIDTH / (16 / 9),
							width: PREVIEW_WIDTH,
						}}
					/>
				) : (
					<div style={{ width: PREVIEW_WIDTH }} />
				)}
			<div className="text-white text-center">
				{displayTime(Math.round(currentHoverTime))}
			</div>
		</div>
	);
};
