import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaBuilding } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import defaultAvatar from '../../../images/default-avatar.png';
import { getFileUrl, CHANNEL_THUMBNAIL_WIDTH } from '../../../lib/file';
import { getChannelLink } from '../../../RoutePath';
import Hexagon from '../../Hexagon/Hexagon';

import './ChannelThumbnail.scss';

const ChannelThumbnail = ({
	channel,
	className,
	isOrganizationChannel,
}) => {
	const getAvatarUrl = (avatar) => (avatar ? getFileUrl(
		{ name: avatar },
		CHANNEL_THUMBNAIL_WIDTH,
	) : defaultAvatar);

	const showOrganizationDefaultLogo = isOrganizationChannel && !channel?.avatar;

	const isPublicOrgChannel = !!channel.linkedOrganization;

	return (
		<Hexagon className={clsx('ChannelThumbnail', className)} size="none" isPublicOrgChannel={isPublicOrgChannel}>
			<Link
				to={getChannelLink(channel.hashtag)}
				style={{
					backgroundImage: showOrganizationDefaultLogo ? '' : `url(${getAvatarUrl(channel.avatar)})`,
				}}
				className={clsx({ 'bg-dark': showOrganizationDefaultLogo, HexagonLinkBlueBorder: isPublicOrgChannel })}
			>
				{showOrganizationDefaultLogo && (
					<FaBuilding className="text-primary m-auto mt-5 pt-2" size={80} />
				)}
				<article className={`ChannelThumbnail_overlay ${channel.relation ? channel.relation.toLowerCase() : ''}`}>
					<p className="ChannelCard_info">
						<strong>{channel.nickname}</strong>
					</p>
					<p className={clsx('ChannelThumbnail_isLive', { isLive: channel.isLive })}>
						<span className="icon-viewers" />
					</p>
					<p className="ChannelThumbnail_viewers">
						{channel.viewers}
					</p>
				</article>
			</Link>
		</Hexagon>
	);
};

ChannelThumbnail.propTypes = {
	channel: PropTypes.shape({
		nickname: PropTypes.string,
		hashtag: PropTypes.string,
		avatar: PropTypes.string,
		isLive: PropTypes.bool,
		viewers: PropTypes.number,
		relation: PropTypes.string,
	}).isRequired,
	className: PropTypes.string,
	isOrganizationChannel: PropTypes.bool,
};

ChannelThumbnail.defaultProps = {
	className: '',
	isOrganizationChannel: false,
};

export default ChannelThumbnail;
