/* eslint-disable react/prop-types */
// @ts-check

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Spinner, Badge } from 'reactstrap';
import { FaXmark } from 'react-icons/fa6';
import { FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa';

import { useFetchSponsoredAds } from '../../../../../api-hooks/ads/ads';
import { useUpdateSponsorAdStatus } from '../../../../../api-hooks/ads/campaign';
import { InlineVideo } from '../../../../../views/Controlroom/Settings/Library/Videos/InlineVideo';
import { formatDate } from '../../../../../lib/time';

export const SponsorAdCampaignStatus = {
	CONFIRMED: 'CONFIRMED',
	PENDING: 'PENDING',
	DECLINED: 'DECLINED',
	NONE: 'NONE',
};

export const SponsorAdsTable = () => {
	const { t } = useTranslation();

	const [previewVideoId, setPreviewVideoId] = useState('');

	const { data: sponsoredAds, isLoading } = useFetchSponsoredAds();
	const { mutateAsync: updateStatus } = useUpdateSponsorAdStatus();

	/**
	 * @param {string} id
	 */
	const togglePreview = (id) => setPreviewVideoId((prevId) => (prevId === id ? '' : id));

	if (isLoading) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<Spinner size="lg" />
			</div>
		);
	}

	if (!sponsoredAds?.length) {
		return <div className="my-5 py-5">{t('AdCampaings.Ad.noSponsor')}</div>;
	}

	return (
		<div className="table-responsive">
			<Table className="TransactionList_table">
				<thead>
					<tr>
						<th className="text-secondary">{t('AdCampaings.Ad.campaignName')}</th>
						<th className="text-secondary">{t('AdCampaings.Ad.sponsoredBy')}</th>
						<th className="text-secondary">{t('AdCampaings.Ad.startDate')}</th>
						<th className="text-secondary">{t('AdCampaings.Ad.endDate')}</th>
						<th className="text-secondary">{t('AdCampaings.Ad.status')}</th>
						<th className="text-secondary">{t('AdCampaings.Ad.actions')}</th>
					</tr>
				</thead>
				<tbody>
					{sponsoredAds?.map((adCampaign) => (
						<React.Fragment key={`ad-${adCampaign._id}`}>
							<tr>
								<td className="text-secondary">{adCampaign?.name}</td>
								<td className="text-secondary">{adCampaign?.owner?.name}</td>
								<td className="text-secondary">{formatDate(adCampaign.startDate, t)}</td>
								<td className="text-secondary">{formatDate(adCampaign.endDate, t)}</td>
								<td className="text-secondary">
									<Badge className="text-dark" color={adCampaign?.sponsorAdStatus === SponsorAdCampaignStatus.CONFIRMED ? 'success' : 'warning'} pill>
										{adCampaign?.sponsorAdStatus === SponsorAdCampaignStatus.CONFIRMED
											? t('AdCampaings.Ad.statusConfirmed')
											: t('AdCampaings.Ad.statusPending')}
									</Badge>
								</td>
								<td className="text-secondary">
									{adCampaign?.sponsorAdStatus === SponsorAdCampaignStatus.PENDING && (
										<>
											<Button
												color="success"
												onClick={() => updateStatus({
													sponsorAdStatus: SponsorAdCampaignStatus.CONFIRMED,
													adCampaignId: adCampaign?._id })}
												size="sm"
												className="mr-2"
											>
												<FaCheck />
											</Button>
											<Button
												color="danger"
												onClick={() => updateStatus({
													sponsorAdStatus: SponsorAdCampaignStatus.DECLINED,
													adCampaignId: adCampaign?._id })}
												size="sm"
												className="mr-2"
											>
												<FaXmark />
											</Button>
										</>
									)}
									<Button
										color="neutral-info"
										onClick={() => togglePreview(adCampaign._id)}
										size="sm"
										title={t('VideosList.togglePreview')}
										className="mr-2"
									>
										{previewVideoId === adCampaign._id ? <FaEyeSlash /> : <FaEye />}
									</Button>
								</td>
							</tr>
							{previewVideoId === adCampaign._id && (
								<tr>
									<td colSpan={6}>
										<div className="mt-2">
											<InlineVideo videoId={adCampaign?.ads[0]?.video?._id} />
										</div>
									</td>
								</tr>
							)}
						</React.Fragment>
					))}
				</tbody>
			</Table>
		</div>
	);
};
