// @ts-check

import { api } from '../api';

/**
 * @import { AxiosResponse } from 'axios';
 * @import { IVideoPublicDto } from '../channel/videos.dto';
 */

export const API_PUBLIC_VIDEO_PATH = '/public/video';

export const fetchOneById = (id) => api.get(
	`${API_PUBLIC_VIDEO_PATH}/${id}`,
);

export const fetchVideoCommentsById = (id) => api.get(
	`${API_PUBLIC_VIDEO_PATH}/${id}/comments`,
);

export const viewVideo = (id, watchId) => api.put(
	`${API_PUBLIC_VIDEO_PATH}/${id}/viewed`,
	{
		watchId,
	},
);

/**
 * @param {string} id
 * @returns {Promise<AxiosResponse<IVideoPublicDto>>}
 */
export const requestPublicVodById = async (
	id,
) => api.post(
	`${API_PUBLIC_VIDEO_PATH}/request/${id}`,
);

export const sendVideoWatchTime = ({
	videoId,
	adBlockInfo,
	playEndTime,
	playStartTime,
	watchId,
	watchedFromResourceId,
	watchedFromResourceType,
}) => api.post(
	`${API_PUBLIC_VIDEO_PATH}/${videoId}/watchTime`,
	{
		adBlockInfo,
		playEndTime,
		playStartTime,
		watchId,
		watchedFromResourceId,
		watchedFromResourceType,
	},
);
