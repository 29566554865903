/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import { VideoSeekSlider } from 'react-video-seek-slider';
import { useTranslation } from 'react-i18next';

import { HLSPlayerVideoTimelinePreview } from './Preview';
import { displayTime } from './helper';

import 'react-video-seek-slider/styles.css';
import './VideoTimeline.scss';

/**
 * @import { IVideoPublicDto } from '../../../api/channel/videos.dto';
 * @import { ESVodResult } from '../../../api/elasticsearch';
 */

/**
 * @typedef {{
 * 		bufferedPercent?: number,
 * 		className?: string,
* 		clipTimecodes?: number[],
 * 		controlsRef?: React.MutableRefObject<HTMLDivElement | null>,
 * 		currentTime: number,
 * 		duration?: number,
 * 		onChangeProgressTime: (time: number) => void,
 * 		timelineRef?: React.MutableRefObject<HTMLDivElement | null>,
 * 		video?: IVideoPublicDto | ESVodResult,
 * }} VideoTimelineProps
 */

/** @type {React.FC<VideoTimelineProps>} */
export const VideoTimeline = ({
	bufferedPercent = 0,
	className = '',
	clipTimecodes = null,
	controlsRef,
	currentTime,
	duration = 0.1, // 0.1 to avoid timeline being "complete" when video not loaded yet
	onChangeProgressTime,
	timelineRef,
	video,
}) => {
	const { t } = useTranslation();

	const startClipped = !!clipTimecodes?.[0];
	const endClipped = clipTimecodes?.[1] && clipTimecodes?.[1] < duration;
	const clipped = startClipped || endClipped;

	const canShowPreview = !!(controlsRef && video?.playbackId && video?.muxTokens?.storyboard);

	return (
		<div
			className={clsx(
				'VideoTimeline d-flex align-items-center flex-grow-1',
				className,
				{
					VideoTimeline__clip__start: startClipped,
					VideoTimeline__clip__end: endClipped,
				},
			)}
		>
			<span
				className={clsx(
					'mr-1 p-1 text-white',
					{ 'pe-2': !!clipTimecodes },
				)}
			>
				{displayTime(currentTime)}
			</span>
			<div className="w-100 h-100" ref={timelineRef}>
				<VideoSeekSlider
					max={duration * 1000}
					currentTime={currentTime * 1000}
					bufferTime={duration * bufferedPercent * 1000}
					onChange={(time) => onChangeProgressTime(time / 1000)}
					offset={0}
					secondsPrefix="00:00:"
					minutesPrefix="00:"
					hideThumbTooltip={canShowPreview}
					timeCodes={clipped ? [
						startClipped && {
							fromMs: 0,
							description: t('VideoTimeline.VideoTimeline.deletedPart'),
						},
						{
							fromMs: clipTimecodes[0] * 1000,
							description: t('VideoTimeline.VideoTimeline.finalVideo'),
						},
						endClipped && {
							fromMs: clipTimecodes[1] * 1000,
							description: t('VideoTimeline.VideoTimeline.deletedPart'),
						},
					].filter((tC) => !!tC) : undefined}
				/>
				{canShowPreview && (
					<HLSPlayerVideoTimelinePreview
						controlsRef={controlsRef}
						duration={duration}
						playbackId={video.playbackId || ''}
						storyboardToken={video.muxTokens?.storyboard || ''}
					/>
				)}
			</div>
			<span
				className={clsx(
					'ml-1 p-1 text-white',
					{ 'ps-2': !!clipTimecodes },
				)}
			>
				{displayTime(duration)}
			</span>
		</div>
	);
};
