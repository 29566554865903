// @ts-check

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { useTalkbackSender, useDeviceStatusFromConfig } from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';
import { ButtonLoadingIcon } from '../../../Button';

/**
 * @typedef {object & {
 * 	className?: string,
 * 	configId?: number,
 * 	iconSize?: number,
 * }} LocalMicProps
 */

export const LocalMic = (
	/** @type {LocalMicProps} */
	{
		className,
		configId = 0,
		iconSize,
		...props
	},
) => {
	const { recipientUsers, stopAllTalkbacks } = useTalkbackSender();
	const isTalkbackSending = recipientUsers.length > 0;

	const {
		device,
		error,
		isActive,
		isLoading,
		permission,
		toggleInputDevice,
	} = useDeviceStatusFromConfig(configId, 'audioinput');

	let shownError = error;
	if (!device) {
		shownError = new Error('Device not found');
	}
	if (permission === 'denied') {
		shownError = new Error('Permission denied');
	}

	let color = 'primary';
	if (shownError) {
		color = 'neutral-danger';
	} else if (!isActive) {
		color = 'neutral-primary';
	} else if (isTalkbackSending) {
		color = 'white';
	}

	let animatedIconColor = 'primary';
	if (shownError) {
		animatedIconColor = 'danger';
	} else if (isTalkbackSending) {
		animatedIconColor = 'success';
	}

	return (
		<>
			<ButtonLoadingIcon
				id="LocalMic"
				tag="span"
				className={clsx(
					'LocalMic btn-animated-icon btn-transition-none',
					`btn-animated-icon--${animatedIconColor} content-${animatedIconColor}`,
					className,
				)}
				color={color}
				loading={isLoading}
				onClick={() => {
					stopAllTalkbacks();
					toggleInputDevice();
				}}
				{...props}
			>
				<span className="btn-wrapper--icon d-inline-block">
					{!isActive
						? <FaMicrophoneSlash size={iconSize} />
						: <FaMicrophone size={iconSize} className={isTalkbackSending ? 'text-success' : ''} />}
				</span>
			</ButtonLoadingIcon>
			<MediaErrorPopover error={shownError} target="LocalMic" type="microphone" />
		</>
	);
};

LocalMic.propTypes = {
	className: PropTypes.string,
	configId: PropTypes.number,
	iconSize: PropTypes.number,
};

LocalMic.defaultProps = {
	className: '',
	configId: 0,
	iconSize: 20,
};
