/* eslint-disable react/prop-types */
// @ts-check

import { useTranslation } from 'react-i18next';

/**
 * @typedef {{
 *  folderName: string,
 * }} ToastNotificationData
 */

/**
 * @typedef {{
 *  data: ToastNotificationData
 * }} ToastNotification
 */

/**
 * @typedef {{
 *  notification: ToastNotification
 * }} NotificationToastFolderPurchaseSuccessProps
 */

export const NotificationToastFolderPurchaseSuccess = (
	/** @type {NotificationToastFolderPurchaseSuccessProps} */
	{ notification },
) => {
	const { t } = useTranslation();
	const {
		data: {
			folderName,
		},
	} = notification;

	return t('Toast.Donation.folderPurchaseSuccess', { folderName });
};
