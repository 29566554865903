/* eslint-disable react/prop-types */
// @ts-check

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { getFileUrl, AVATAR_SIZE } from '../../lib/file';
import Hexagon from '../Hexagon/Hexagon';
import defaultAvatar from '../../images/default-avatar.png';
import { getChannelLink } from '../../RoutePath';
// eslint-disable-next-line import/no-unresolved
import { ReactComponent as BeeyouLogo } from '../../images/logo.@@PROJECT.svg';

/**
 * @typedef {{
 * 	avatarUrl?: string;
 * 	className?: string;
 * 	hashtag?: string;
 * 	onClick?: () => void,
 * 	showPlatformAsDefault?: boolean;
 * 	small?: boolean;
 * }} ProfileAvatarProps
 */

/** @type {React.FC<ProfileAvatarProps>} */
export const ProfileAvatar = ({
	avatarUrl,
	className,
	hashtag,
	onClick,
	showPlatformAsDefault = false,
	small = false,
}) => {
	const { t } = useTranslation();
	// eslint-disable-next-line no-nested-ternary
	const userAvatar = avatarUrl
		? getFileUrl({ name: avatarUrl }, AVATAR_SIZE.width)
		: !showPlatformAsDefault
			? defaultAvatar
			: undefined;

	const avatar = (
		<Hexagon
			className={clsx(
				'avatar-icon shadow-none',
				small && '-mini',
				className || 'mr-3 mt-1',
			)}
		>
			{userAvatar ? (
				<img alt={t('Profile.Avatar.avatar')} src={userAvatar} />
			) : (
				<BeeyouLogo />
			)}
		</Hexagon>
	);

	if (onClick) {
		return (
			<div className="cursor-pointer" onClick={onClick}>
				{avatar}
			</div>
		);
	}

	if (hashtag) return <Link to={getChannelLink(hashtag)}>{avatar}</Link>;

	return avatar;
};
