import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Badge, Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FaCalendarDays } from 'react-icons/fa6';

import { useProfile } from '../../Profile/ProfileContext';
import { useAuthentication } from '../../Authentication/Authentication';
import { ConfirmationModal } from '../../../layout-components/ConfirmationModal';
import { useCreateCalendarEvent, useFetchStudioCalendarStatus } from '../../../api-hooks/studio/schedule';
import { formatLiveDate } from '../../../lib/time';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import defaultAvatar from '../../../images/default-avatar.png';
import { getFileUrl, AVATAR_SIZE, COVER_SIZE } from '../../../lib/file';
import { ButtonPill } from '../../Button/Pill';
import Hexagon from '../../Hexagon/Hexagon';
import { liveStatusMessage } from '../helper';
import { ListTitle } from '../ItemList/ListTitle';
import { getChannelLink, getWatchLink } from '../../../RoutePath';
import { hasAvailablePresale, getStudioPriceWithPresale } from '../../Presale/PresaleUtil';

import './Slider.scss';
import { currencyLabel } from '../../../lib/currency';
import { convertToUsd } from '../../../lib/prices';

const CustomArrow = ({ className, style, onClick, actionOnClick, direction }) => (

	<span
		className={clsx(`text-primary shadow-none bg-transparent d-flex align-items-center ${className}`,
			{ 'justify-content-end': direction === 'right' },
			{ 'justify-content-start': direction === 'left' })}
		style={{
			...style,
			fontSize: '2rem',
		}}
		onClick={() => {
			onClick();
			actionOnClick();
		}}
	>
		{direction === 'left' ? <FaChevronLeft fontSize="1.6rem" /> : <FaChevronRight fontSize="1.6rem" />}
	</span>

);

CustomArrow.propTypes = {
	actionOnClick: PropTypes.func,
	className: PropTypes.string,
	direction: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.shape({}),
};

CustomArrow.defaultProps = {
	actionOnClick: undefined,
	className: '',
	direction: 'left',
	onClick: undefined,
	style: {},
};

export const ChannelSliderItem = ({
	className,
	live,
	refetch,
}) => {
	const { t } = useTranslation();
	const timeoutRef = useRef();
	const [reRenderStatus, setReRenderStatus] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const { profile } = useProfile();
	const { isLoggedIn } = useAuthentication();

	const userAvatar = live.channelAvatar
		? getFileUrl({ name: live.channelAvatar }, AVATAR_SIZE.width, AVATAR_SIZE.height)
		: defaultAvatar;
	const banner = live.banner || live.channelCover;

	const isStudioRunning = live?.status === 'RUNNING';
	const isLiveActive = live?.liveStatus === 'active';
	const isLiveAlmostTerminated = isLiveActive && !isStudioRunning;
	const isLivePlanned = !isLiveActive && !isLiveAlmostTerminated;
	const isOwnStudio = live?.ownerId === profile?._id;

	const { mutate: createCalendarEvent } = useCreateCalendarEvent(live._id);
	const {
		data: isStudioAddedToCalendar,
		isLoading: isCalendarStatusLoading,
	} = useFetchStudioCalendarStatus(live._id, isLoggedIn);

	const liveDateWithoutTime = formatLiveDate(live?.startAt, t, true);

	useEffect(() => {
		if (new Date(live.startAt).getTime() > new Date().getTime()) {
			timeoutRef.current = setTimeout(() => {
				setReRenderStatus(Date.now());
				if (refetch) {
					refetch();
				}
			},
			new Date(live.startAt).getTime() - new Date().getTime() + 5000);
		}
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [live.startAt, reRenderStatus, refetch]);

	// hardcoded viewer because this is slider for homepage, public lives only
	const livePrice = getStudioPriceWithPresale(live, ResourceAccessRole.VIEWER);

	const watchButtonLabel = useMemo(() => {
		let baseLabel;
		if (hasAvailablePresale(live, ResourceAccessRole.VIEWER)) {
			baseLabel = t('Presale.BuyTicket');
		} else if (isStudioRunning) {
			baseLabel = t('Slider.Slider.watchLive');
		} else {
			baseLabel = `${t('Slider.Slider.watch')} ${liveDateWithoutTime}`;
		}

		return livePrice
			? `${baseLabel} ${t('Slider.Slider.for')} ${convertToUsd(livePrice)}${currencyLabel}`
			: baseLabel;
	}, [live, isStudioRunning, liveDateWithoutTime, livePrice, t]);

	const cancelAddToCalendar = useCallback(() => setShowConfirmationModal(false),
		[setShowConfirmationModal]);

	const handleAddLiveToCalendar = useCallback(async () => {
		createCalendarEvent({}, {
			onSuccess: cancelAddToCalendar,
		});
	}, [createCalendarEvent, cancelAddToCalendar]);

	const showAddToCalendarButton = !isStudioAddedToCalendar && isLoggedIn && !isOwnStudio
	&& !isLiveActive && !isCalendarStatusLoading;

	return (
		<Card className={clsx('ChannelSliderItem card-transparent rounded overflow-hidden fix-overflow position-relative', className)}>
			{banner && (
				<img
					src={getFileUrl({ name: `${banner}` }, COVER_SIZE.width)}
					className="ChannelSliderItem_cover card-img-top bg-dark"
					alt={t('Slider.Slider.liveBanner')}
				/>
			)}
			<div className="ChannelSliderItem_gradient position-absolute h-100 text-black d-flex px-2 py-1 px-md-3 py-md-3">
				<article className="ChannelSliderItem_content d-flex flex-column px-2 py-1 px-md-3 py-md-3 h-100">
					<div className="d-flex align-items-center gap-1">
						<span>{t('Slider.Slider.livestream')}</span>
						{isLiveActive && (
							<div className="ChannelCard_top d-flex ml-2">
								<Badge pill color="danger">Live</Badge>
							</div>
						)}
					</div>
					<div className="d-flex flex-column my-1 my-md-2">
						<h1 className="ChannelSliderItem_title text-capitalize" title={live.subject}>{live.subject}</h1>
						<Link
							className="d-flex align-items-center text-first mr-auto"
							to={getChannelLink(live.channelHashtag)}
						>
							<Hexagon className="avatar-icon rounded-0 shadow-none mr-2">
								<img className="img-fit-container" alt={t('Slider.Slider.avatar')} src={userAvatar} />
							</Hexagon>
							<strong>{live.channelLabel}</strong>
						</Link>
					</div>
					<div className="d-flex flex-column mt-auto">
						<div className="flex flex-row">
							<ButtonPill
								className="ChannelSliderItem_button mr-2"
								color="black"
								tag={Link}
								to={getWatchLink(live?.channelHashtag, live?.watchLinkId)}
							>
								{watchButtonLabel}
							</ButtonPill>
							{showAddToCalendarButton && (
								<ButtonPill
									onClick={() => setShowConfirmationModal(true)}
									className="SliderCalendarIcon"
								>
									<FaCalendarDays />
								</ButtonPill>
							)}
						</div>
						<p className="mt-1 mt-md-2 mb-0 ml-2 font-size-sm">
							{(isLiveActive && !isLiveAlmostTerminated) && 'LIVE NOW!'}
							{isLiveAlmostTerminated && 'Live is almost terminated.'}
							{isLivePlanned && liveStatusMessage(live?.startAt, t)}
						</p>
						{hasAvailablePresale(live, ResourceAccessRole.VIEWER) && (
							<p className="mt-1 mt-md-2 mb-0 font-size-sm">
								{t(
									'Presale.AccessCost',
									{ price: convertToUsd(live.price.viewer || 0), currencyLabel },
								)}
							</p>
						)}
					</div>
				</article>
			</div>
			<ConfirmationModal
				message={t('Slider.Slider.addEventToCalendar')}
				isOpen={showConfirmationModal}
				onConfirm={handleAddLiveToCalendar}
				onDeny={cancelAddToCalendar}
			/>
		</Card>
	);
};

ChannelSliderItem.propTypes = {
	className: PropTypes.string,
	refetch: PropTypes.func,
	live: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		channelHashtag: PropTypes.string.isRequired,
		channelLabel: PropTypes.string.isRequired,
		channelAvatar: PropTypes.string,
		channelCover: PropTypes.string,
		banner: PropTypes.string,
		liveStatus: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		startAt: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		price: PropTypes.shape({
			participant: PropTypes.number,
			viewer: PropTypes.number,
			participantPerMinute: PropTypes.number,
			viewerPerMinute: PropTypes.number,
		}),
		watchLinkId: PropTypes.string.isRequired,
		ownerId: PropTypes.string,
	}).isRequired,
};

ChannelSliderItem.defaultProps = {
	className: '',
	refetch: undefined,
};

export const ChannelSlider = forwardRef(({
	className,
	refetch,
	title,
	lives,
}, ref) => {
	const [isScreenXL, setIsScreenXL] = useState(false);
	const [sliderSlow, setSliderSlow] = useState(false);
	const sliderRef = useRef();
	const timeoutRef = useRef();
	const getScreenXLMatcher = () => {
		const xlMin = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xl');
		return window.matchMedia(`(min-width: ${xlMin})`);
	};

	const filteredLives = useMemo(() => lives.filter((live) => live.liveStatus !== 'live_stopped'), [lives]);

	useEffect(() => {
		const matcher = getScreenXLMatcher();
		const handleChangeMatchScreenXL = () => {
			setIsScreenXL(matcher.matches);
		};
		setIsScreenXL(matcher.matches);
		matcher.addEventListener('change', handleChangeMatchScreenXL);
		return () => {
			matcher.removeEventListener('change', handleChangeMatchScreenXL);
		};
	}, []);

	useEffect(() => {
		if (sliderSlow) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = setTimeout(() => {
				setSliderSlow(false);
				sliderRef.current.slickPlay();
			}, 15000);
			sliderRef.current.slickPause();
		}
		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [sliderSlow]);

	return filteredLives?.length > 0 && (
		<section className={clsx(className)} ref={ref}>
			{title && <ListTitle className="mb-1" title={title} />}
			<Slider
				ref={sliderRef}
				autoplay
				pauseOnFocus
				pauseOnHover
				centerMode={isScreenXL && filteredLives?.length > 3}
				centerPadding={0}
				className={clsx('ChannelSlider center h-100', { 'SliderXL mw-100 p-0': isScreenXL && filteredLives.length > 3 })}
				dots
				dotsClass="slick-dots mt-3"
				focusOnSelect
				infinite
				slidesToScroll={1}
				slidesToShow={isScreenXL && filteredLives?.length > 3 ? 3 : 1}
				speed={500}
				autoplaySpeed={3000}
				prevArrow={<CustomArrow actionOnClick={() => setSliderSlow(true)} direction="left" />}
				nextArrow={<CustomArrow actionOnClick={() => setSliderSlow(true)} direction="right" />}
				onSwipe={() => setSliderSlow(true)}
			>
				{filteredLives.map((live) => (
					<div key={live.code}>
						<ChannelSliderItem
							refetch={refetch}
							live={live}
						/>
					</div>
				))}
			</Slider>
		</section>
	);
});

ChannelSlider.propTypes = {
	className: PropTypes.string,
	refetch: PropTypes.func,
	title: PropTypes.string,
	lives: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		channelHashtag: PropTypes.string.isRequired,
		channelLabel: PropTypes.string.isRequired,
		channelAvatar: PropTypes.string,
		channelCover: PropTypes.string,
		banner: PropTypes.string,
		liveStatus: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		startAt: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		price: PropTypes.shape({
			participant: PropTypes.number,
			viewer: PropTypes.number,
			participantPerMinute: PropTypes.number,
			viewerPerMinute: PropTypes.number,
		}),
	})).isRequired,
};

ChannelSlider.defaultProps = {
	className: '',
	refetch: undefined,
	title: null,
};
