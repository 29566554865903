import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Label,
	FormGroup,
	Input,
	Alert,
	Collapse,
} from 'reactstrap';
import clsx from 'clsx';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
	getVirtualDeviceById,
	KeyDetectionMode,
	useInputs,
	useMediaKey,
} from '@technomiam/react-video';
import { useAsyncCallback } from 'react-async-hook';

import { PublicPathPrefix } from '../../../RoutePath';
import { LocalMic } from '../../Channel/ChannelButtonsPanel/LocalUserMedia/LocalMic';
import { LocalCam } from '../../Channel/ChannelButtonsPanel/LocalUserMedia/LocalCam';
import { MediaKeyForm } from '../Key/Form';
import { ButtonLoading, ButtonPill } from '../../Button';

const getDeviceLabelByDeviceId = (virtualDeviceId) => (
	getVirtualDeviceById(virtualDeviceId)?.label || virtualDeviceId
);

export const InputsSelector = ({
	config,
	size,
	onKeySelectBackground,
}) => {
	const { t } = useTranslation();
	const {
		audioInputDevices,
		changeAudioInputDevice,
		changeVideoInputDevice,
		currentlyEnabledDefaultDevices,
		inputPermissions,
		videoInputDevices,
		inputsConfig,
		requestInputPermissions,
	} = useInputs();
	const configId = config.id;
	const {
		config: keyConfig,
		isKeyForced,
		isReplacementConfigOverriden,
	} = useMediaKey();
	const [keySettingsOpen, setKeySettingsOpen] = useState(false);

	const handleAudioSourceChange = (event) => {
		const { value } = event.target;
		changeAudioInputDevice(
			configId,
			value || undefined,
		);
	};

	const handleVideoSourceChange = (event) => {
		const { value } = event.target;
		changeVideoInputDevice(
			configId,
			value || undefined,
		);
	};

	const otherInputConfigs = inputsConfig.filter((cfg) => cfg.id !== configId);

	const availableAudioDevices = (audioInputDevices || []).filter(
		({ virtualDeviceId }) => !otherInputConfigs.find(
			({ audioInputId }) => audioInputId === virtualDeviceId,
		),
	);
	const availableVideoDevices = (videoInputDevices || []).filter(
		({ virtualDeviceId }) => !otherInputConfigs.find(
			({ videoInputId }) => videoInputId === virtualDeviceId,
		),
	);
	const audioDeviceFound = audioInputDevices.find(({ virtualDeviceId }) => (
		virtualDeviceId === config?.audioInputId
	));
	const videoDeviceFound = videoInputDevices.find(({ virtualDeviceId }) => (
		virtualDeviceId === config?.videoInputId
	));

	const isDeviceBusy = (inputId) => configId !== 0
		&& currentlyEnabledDefaultDevices.find(({ virtualDeviceId }) => (
			virtualDeviceId === inputId
		));

	const showPermissionIssue = (
		inputPermissions?.videoinput !== 'granted'
		|| inputPermissions?.audioinput !== 'granted'
	);
	const isKeyEnabled = keyConfig?.detection?.mode
		&& keyConfig.detection.mode !== KeyDetectionMode.DISABLED;

	const askPermission = async () => {
		await requestInputPermissions({
			audioinput: inputPermissions?.audioinput !== 'granted',
			videoinput: inputPermissions?.videoinput !== 'granted',
		});
		await new Promise((resolve) => { setTimeout(resolve, 5000); });
	};

	const asyncAskPermission = useAsyncCallback(askPermission);

	const asyncAskPermissionRef = useRef(asyncAskPermission);
	asyncAskPermissionRef.current = asyncAskPermission;

	useEffect(() => {
		if (
			asyncAskPermissionRef.current
			&& inputPermissions?.audioinput === 'granted'
			&& inputPermissions?.videoinput === 'granted'
		) {
			asyncAskPermissionRef.current.reset();
		}
	}, [inputPermissions]);

	return (
		<div>
			{showPermissionIssue && (
				<Alert color="danger">
					<p>
						{t('Inputs.Selector.noDevices')}
						<br />
						{t('Inputs.Selector.seeOur')} <a href={`${PublicPathPrefix}/help/troubleshooting`} target="_blank" rel="noopener noreferrer">{t('Inputs.Selector.troobleShootingGuide')} <FaExternalLinkAlt /></a>.
					</p>
					<p className="d-flex align-items-center justify-content-center">
						<ButtonLoading
							component={ButtonPill}
							color="danger"
							loading={asyncAskPermission.loading}
							onClick={asyncAskPermission.execute}
						>
							{t('Inputs.Selector.InputPermissionsIssue.askPermission')}
						</ButtonLoading>
					</p>
					{asyncAskPermission.error && (
						<div>
							<p className="mb-0">
								{t('Inputs.Selector.InputPermissionsIssue.error.intro')}
							</p>
							<ul>
								<li>
									<a href="#" onClick={() => { window.location.reload(true); }}>
										{t('Inputs.Selector.InputPermissionsIssue.error.options.reload')}
									</a>,
								</li>
								<li>
									{t('Inputs.Selector.InputPermissionsIssue.error.options.browserSettings')},
								</li>
								<li>
									{t('Inputs.Selector.InputPermissionsIssue.error.options.checkGuide')} <a href={`${PublicPathPrefix}/help/troubleshooting`} target="_blank" rel="noopener noreferrer">{t('Inputs.Selector.troobleShootingGuide')} <FaExternalLinkAlt /></a>
								</li>
							</ul>
							<p className="font-size-sm p-2 bg-white rounded rounded-sm">
								<strong>{t('Inputs.Selector.InputPermissionsIssue.error.error')}</strong><br />
								{asyncAskPermission.error.name}:{' '}
								{asyncAskPermission.error.message}
							</p>
						</div>
					)}
				</Alert>
			)}
			<FormGroup className={clsx({ 'mb-2': size === 'sm' })}>
				<Label className={clsx({ 'mb-1 p-0': size === 'sm' })} htmlFor="videoSource" size={size}>{t('Inputs.Selector.selectVideoSource')}</Label>
				<div className="d-flex align-items-center">
					<LocalCam configId={config.id} className="d-40 p-0 mr-1 flex-shrink-0" title={t('Inputs.Selector.camera')} />
					<Input
						disabled={videoInputDevices.length < 1}
						id="videoSource"
						name="videoSource"
						onChange={handleVideoSourceChange}
						bsSize={size}
						type="select"
						value={config.videoInputId || ''}
					>
						{(config.id !== 0 || videoInputDevices.length < 1) && (
							<option value="">{t('Inputs.Selector.none')}</option>
						)}
						{/* {(config.id === 0) && (
							<option value={DEFAULT_DEVICES.cam.deviceId}>
								{DEFAULT_DEVICES.cam.label}
								{videoDeviceFound && ` (${videoDeviceFound.label})`}
							</option>
						)} */}
						{availableVideoDevices.map((device) => (
							<option
								key={device.virtualDeviceId}
								value={device.virtualDeviceId}
							>
								{isDeviceBusy(device.virtualDeviceId) && `[${t('Inputs.Selector.busy')}] `}
								{device.label}
							</option>
						))}
						{(
							!!config.videoInputId
							&& !videoDeviceFound
						) && (
							<option
								key={config.videoInputId}
								value={config.videoInputId}
							>
								[{t('Inputs.Selector.disconnected')}] {getDeviceLabelByDeviceId(config.videoInputId)}
							</option>
						)}
					</Input>
				</div>
			</FormGroup>
			{config.id === 0 && (
				<section>
					<header>
						<button
							className="btn btn-dark d-flex w-100 justify-content-center shadow-none"
							disabled={isKeyForced}
							type="button"
							onClick={() => setKeySettingsOpen((s) => !s)}
						>
							{isKeyForced
								? 'Key config overriden by current scene settings'
								: (
									<>
										{t('Inputs.Selector.keySettings', { context: keySettingsOpen ? 'hide' : 'show' })}
										{' ('}{t('Global.Status', { context: isKeyEnabled ? 'enabled' : 'disabled' })})
									</>
								)}
						</button>
					</header>
					<Collapse isOpen={!isKeyForced && keySettingsOpen}>
						<div className="p-3 bg-dark">
							{keySettingsOpen && (
								<MediaKeyForm
									initialValue={keyConfig}
									isReplacementConfigOverriden={isReplacementConfigOverriden}
									onKeySelectBackground={onKeySelectBackground}
								/>
							)}
						</div>
					</Collapse>
				</section>
			)}
			<FormGroup className={clsx('mt-2', { 'mb-2': size === 'sm' })}>
				<Label className={clsx({ 'mb-1 p-0': size === 'sm' })} htmlFor="audioSource" size={size}>{t('Inputs.Selector.selectAudioSource')}</Label>
				<div className="d-flex align-items-center">
					<LocalMic configId={config.id} className="d-40 p-0 mr-1 flex-shrink-0" title={t('Inputs.Selector.mic')} />
					<Input
						disabled={audioInputDevices.length < 1}
						id="audioSource"
						name="audioSource"
						onChange={handleAudioSourceChange}
						bsSize={size}
						type="select"
						value={config.audioInputId || ''}
					>
						{(config.id !== 0 || audioInputDevices.length < 1) && (
							<option value="">{t('Inputs.Selector.none')}</option>
						)}
						{/* {(config.id === 0) && (
							<option value={DEFAULT_DEVICES.mic.deviceId}>
								{DEFAULT_DEVICES.mic.label}
								{audioDeviceFound && ` (${audioDeviceFound.label})`}
							</option>
						)} */}
						{availableAudioDevices.map((device) => (
							<option
								key={device.virtualDeviceId}
								value={device.virtualDeviceId}
							>
								{isDeviceBusy(device.virtualDeviceId) && `[${t('Inputs.Selector.busy')}] `}
								{device.label}
							</option>
						))}
						{(
							!!config.audioInputId
							&& !audioDeviceFound
						) && (
							<option
								key={config.audioInputId}
								value={config.audioInputId}
							>
								[{t('Inputs.Selector.disconnected')}] {getDeviceLabelByDeviceId(config.audioInputId)}
							</option>
						)}
					</Input>
				</div>
			</FormGroup>
		</div>
	);
};

InputsSelector.propTypes = {
	config: PropTypes.shape({
		audioInputId: PropTypes.string,
		id: PropTypes.number,
		videoInputId: PropTypes.string,
	}),
	size: PropTypes.string,
	onKeySelectBackground: PropTypes.func,
};

InputsSelector.defaultProps = {
	config: {},
	size: undefined,
	onKeySelectBackground: undefined,
};
