// @ts-check

import { api } from '../api';

/**
 * @import { SocialNetworkPlatform, ISocialNetworkDto } from './socialNetworks.dto';
 * @import { IVideoOwnerDto } from './videos.dto';
 * @import { AxiosResponse } from 'axios';
 */

export const API_CHANNEL_SOCIAL_NETWORKS_PATH = '/channel/socialnetworks';

/**
 * @returns {Promise<AxiosResponse<ISocialNetworkDto[]>>}
 */
export const fetchSocialNetworks = async () => api.get(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}`,
);

/**
 * @param {{
 * 	platform: SocialNetworkPlatform,
 * 	code: string,
 * }} param0
 * @returns {Promise<AxiosResponse<ISocialNetworkDto>>}
 */
export const linkSocialNetwork = async ({ platform, code }) => api.post(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/${platform}`,
	{ code },
);

/**
 * @param {{
* 	platform: SocialNetworkPlatform,
* }} param0
* @returns {Promise<AxiosResponse<void>>}
*/
export const deleteSocialNetwork = async ({ platform }) => api.delete(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/${platform}`,
);

/**
 * @param {{
* 	platforms: SocialNetworkPlatform[],
* 	videoId: string,
* 	caption?: string,
* }} param0
* @returns {Promise<AxiosResponse<IVideoOwnerDto>>}
*/
export const uploadVideoToSocialNetwork = async ({ platforms, videoId, caption }) => api.post(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/videos/${videoId}`,
	{ caption: caption || '', platforms },
);

/**
 * @param {string} videoId
* @returns {Promise<AxiosResponse<IVideoOwnerDto>>}
*/
export const fetchVideoWithSocialNetworkUploads = async (videoId) => api.get(
	`${API_CHANNEL_SOCIAL_NETWORKS_PATH}/videos/${videoId}`,
);
