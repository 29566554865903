// @ts-check

import { useMemo, useRef } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { requestPublicVodById } from '../../api/public/video';
import { getVideoSrc } from '../../lib/file';

const MIN_REFETCH_INTERVAL = 1000;

/**
 * @param {{
 * 	src: string;
 * 	videoId: string | null;
 * }} param0
 * @returns {{
 * 		handleRefetchSrc: () => Promise<string>;
 * 		validSrc: string;
 * }}
 */
export const useHLSPlayerSrcRefetcher = ({ src, videoId }) => {
	const lastRefetchTime = useRef(0);

	const { result, execute: handleRefetchSrc } = useAsyncCallback(async () => {
		if (!videoId) return src;

		const now = Date.now();
		if (now - lastRefetchTime.current < MIN_REFETCH_INTERVAL) return src;
		lastRefetchTime.current = now;

		const { data: video } = await requestPublicVodById(videoId);
		return getVideoSrc(video);
	});

	const validSrc = result || src;

	return useMemo(() => ({
		handleRefetchSrc,
		validSrc,
	}), [
		handleRefetchSrc,
		validSrc,
	]);
};
