// @ts-check

import { api } from '../api';

/**
 * @import { AxiosResponse } from 'axios';
 * @import { IVideoOwnerDto } from './videos.dto';
 * @import { PaginatedResult } from '../helpers';
 */

export const API_CHANNEL_VIDEOS_PATH = '/channel/videos';

export const createVideo = async ({ channelId, video }) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/toChannelById/${channelId}`,
	video,
);

export const deleteVideo = (videoId) => api.delete(
	`${API_CHANNEL_VIDEOS_PATH}/${videoId}`,
);

/**
 * @param {string | undefined} channelId
 * @param {'beeyou' | 'all' | undefined} mode
 * @param {number | undefined} itemsPerPage
 * @param {number | undefined} currentPage
 * @param {string | undefined} folderId
 * @param {boolean | undefined} ignoreFolders
 * @returns {Promise<AxiosResponse<PaginatedResult<IVideoOwnerDto>>>}
 */
export const fetchVideos = (
	channelId,
	mode,
	itemsPerPage,
	currentPage,
	folderId,
	ignoreFolders = false,
) => api.get(
	`${API_CHANNEL_VIDEOS_PATH}`,
	{
		params: {
			channelId,
			mode,
			itemsPerPage,
			currentPage,
			folderId,
			ignoreFolders,
		},
	},
);

export const fetchVodByHashtag = (hashtag) => api.get(
	`${API_CHANNEL_VIDEOS_PATH}/vod/byhashtag/${hashtag}`,
);

export const fetchVodById = (id) => api.get(`${API_CHANNEL_VIDEOS_PATH}/${id}`);

export const updateVideo = (video) => api.put(
	API_CHANNEL_VIDEOS_PATH,
	video,
);

export const cloneVideo = ({ cloneFromVideoId, videoData }) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/clone/${cloneFromVideoId}`,
	videoData,
);

export const buyVodAccess = (id) => api.post(`${API_CHANNEL_VIDEOS_PATH}/${id}/access`);

/**
 * @param {string} videoId
 * @returns {Promise<AxiosResponse<string>>}
 */
export const createShareableLink = (videoId) => api.post(`${API_CHANNEL_VIDEOS_PATH}/privateShareId/${videoId}`);

export const getDownloadLink = (videoId) => api.get(`${API_CHANNEL_VIDEOS_PATH}/download/${videoId}`);

export const viewVideo = (id, watchId) => api.put(
	`${API_CHANNEL_VIDEOS_PATH}/${id}/viewed`,
	{
		watchId,
	},
);

export const sendVideoWatchTime = ({
	videoId,
	adBlockInfo,
	playEndTime,
	playStartTime,
	watchedFromResourceId,
	watchedFromResourceType,
	watchId,
}) => api.post(
	`${API_CHANNEL_VIDEOS_PATH}/${videoId}/watchTime`,
	{
		adBlockInfo,
		playEndTime,
		playStartTime,
		watchedFromResourceId,
		watchedFromResourceType,
		watchId,
	},
);

/**
 * @param {string} videoId
 * @returns {Promise<AxiosResponse<void>>}
 */
export const deleteShareableLink = (videoId) => api.delete(`${API_CHANNEL_VIDEOS_PATH}/privateShareId/${videoId}`);
