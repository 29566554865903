// @ts-check

import axios from 'axios';

const baseUrlMuxThumbnail = import.meta.env.VITE_MUX_THUMBNAIL;

/**
 * @import { AxiosResponse } from 'axios';
 */

/**
 * @typedef {{
* 	url: string,
*  tile_width: number,
*  tile_height: number,
*  duration: number,
*  tiles: {
*  		start: number,
*  		x: number,
* 		y: number,
* 	}[],
* }} MuxStoryboard
*/

/**
 * @param {string} playbackId
 * @param {string} token
 * @returns {Promise<AxiosResponse<MuxStoryboard>>}
 */
export const fetchMuxStoryboard = (playbackId, token) => axios.get(`${baseUrlMuxThumbnail}${playbackId}/storyboard.json?token=${token}`);
